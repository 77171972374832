import { css } from '@emotion/core';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Desktop, Tablet } from '../../constants/responsive';
import { graphql, useStaticQuery } from 'gatsby';
import ScrollContainer from 'react-indiana-drag-scroll';
const StocksCard = ({ name, img, bottomRef }) => {
  const intl = useIntl();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 18px;
        :last-child {
          margin-right: 0px;
        }
        ${Tablet} {
          margin-right: 0;
        }
      `}
    >
      {/* 图 */}
      <div
        css={css`
          z-index: 6;
          border-radius: 50%;
          background: url(${img}) center/auto 100% no-repeat border-box
            border-box;
          background-size: cover;

          width: 68px;
          height: 68px;
          border: 6px solid #ffffff;
          ${Tablet} {
            width: 72px;
            height: 72px;
            border: 7px solid #ffffff;
          }
          ${Desktop} {
            width: 110px;
            height: 110px;
            border: 9px solid #ffffff;
          }
        `}
      ></div>

      <div
        css={css`
          z-index: 5;
          position: relative;
          border-radius: 15px;
          box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.05);
          background-color: #ffffff;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          flex: 0 0 auto;
          margin-top: -32px;
          width: 223px;
          height: 249px;
          padding: 47px 30px 0 30px;
          margin-right: 0;
          ${Tablet} {
            margin-top: -32px;
            width: 223px;
            height: 249px;
            padding: 47px 30px 0 30px;
            margin-right: 0;
          }
          ${Desktop} {
            margin-top: -55px;
            width: 352px;
            height: 288px;
            padding: 70px 36px 0 33px;
          }
        `}
      >
        <div
          css={css`
            font-size: 16px;
            font-weight: 500;
            line-height: 1.13;
            // height: 100%;
            display: flex;
            align-items: center;
            ${Tablet} {
              font-size: 18px;
              font-weight: normal;
              line-height: 1.11;
              height: 48px;
            }
            ${Desktop} {
              font-size: 24px;
              font-weight: 500;
              line-height: 1.33;
              height: auto;
            }
          `}
        >
          {intl.formatMessage({ id: `individuals.stock-cards.${name}.title` })}
        </div>
        <div
          css={css`
            color: #161616;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.71;
            margin-top: 14px;
            ${Tablet} {
              font-size: 14px;
              font-weight: normal;
              line-height: 1.71;
              margin-top: 9px;
            }
            ${Desktop} {
              font-size: 18px;
              font-weight: normal;
              line-height: 1.43;
              margin-top: 12px;
            }
          `}
        >
          {intl.formatMessage({
            id: `individuals.stock-cards.${name}.description`,
          })}
        </div>
        <div
          css={css`
            border-radius: 41px;
            // box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
            background-color: #ee2f79;
            width: 113px;
            height: 32px;

            font-size: 12px;
            font-weight: bold;
            text-align: center;
            color: #ffffff;
            line-height: 32px;

            position: absolute;
            bottom: 31px;
            // pointer-events: ${name === 'us' ? 'none' : 'auto'};
            cursor: pointer;
            ${Tablet} {
              width: 113px;
              height: 31px;

              font-size: 12px;
              line-height: 31px;

              position: absolute;
              bottom: 40px;
            }
            ${Desktop} {
              width: 178px;
              height: 47px;

              font-size: 16px;
              line-height: 47px;

              position: absolute;
              bottom: 61px;
            }
          `}
          onClick={() => {
            window.scrollTo({
              top: bottomRef.current.offsetTop,
              behavior: 'smooth',
            });
          }}
        >
          {intl.formatMessage({ id: `individuals.stock-cards.${name}.btn` })}
        </div>
      </div>
    </div>
  );
};

const StockTradingStocksCards = ({ bottomRef }) => {
  const intl = useIntl();
  const data = useStaticQuery(graphql`
    query {
      China: file(relativePath: { eq: "stock-trading/China.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      HK: file(relativePath: { eq: "stock-trading/HK.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      USA: file(relativePath: { eq: "stock-trading/USA.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return (
    <div>
      {/* 【StocksCards】- WEB端、IPAD端 */}
      <div
        css={css`
          display: none;
          ${Tablet} {
            display: flex;
            justify-content: space-between;
          }
          ${Desktop} {
            display: flex;
            justify-content: space-between;
          }
        `}
      >
        <StocksCard
          img={data[`China`].childImageSharp.fluid.src}
          name='northbound'
          bottomRef={bottomRef}
        ></StocksCard>
        <StocksCard
          img={data[`HK`].childImageSharp.fluid.src}
          name='hk'
          bottomRef={bottomRef}
        ></StocksCard>
        <StocksCard
          img={data[`USA`].childImageSharp.fluid.src}
          name='us'
          bottomRef={bottomRef}
        ></StocksCard>
      </div>
      {/* 【StocksCards】- MOBILE端 */}
      <ScrollContainer
        css={css`
          display: flex;
          justify-content: space-between;
          width: 100%;
          // margin-top: 20px;
          // margin-left: 25px;
          padding: 10px 10px 10px 20px;
          ${Tablet} {
            display: none;
          }
        `}
      >
        <StocksCard
          img={data[`China`].childImageSharp.fluid.src}
          name='northbound'
          bottomRef={bottomRef}
        ></StocksCard>
        <StocksCard
          img={data[`HK`].childImageSharp.fluid.src}
          name='hk'
          bottomRef={bottomRef}
        ></StocksCard>
        <StocksCard
          img={data[`USA`].childImageSharp.fluid.src}
          name='us'
          bottomRef={bottomRef}
        ></StocksCard>
      </ScrollContainer>
    </div>
  );
};

export default StockTradingStocksCards;
