import { css } from '@emotion/core';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Desktop, Tablet } from '../../constants/responsive';

const Option = () => {
  return (
    <div
      css={css`
        ${Desktop} {
          width: 290px;
          height: 290px;
          border-radius: 15px;
          padding: 40px 20px 0 20px;
          box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.1);
          margin-right: 31.7px;
          :last-child {
            margin-right: 0;
          }
        }
      `}
    >
      <div>Long-Term Asset Allocation Portfolios</div>
    </div>
  );
};

const OtherInvestmentOptions = () => {
  const intl = useIntl();

  return (
    <div
      css={css`
        ${Desktop} {
          display: flex;
        }
      `}
    >
      <Option></Option>
      <Option></Option>
      <Option></Option>
    </div>
  );
};

export { OtherInvestmentOptions, Option };
