import { css } from '@emotion/core';
import { useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import { Desktop, Tablet } from '../../constants/responsive';
import Container from '../Container';

const StockTradingBanner = () => {
  const intl = useIntl();

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        ${Tablet} {
          display: flex;
          flex-direction: column-reverse;
          width: 100%;
          // height: 650px;
        }
        ${Desktop} {
          width: 100%;
          height: 615px;
          background-color: #ee2f79;
          display: flex;
          flex-direction: row;
          position: relative;
          overflow: hidden;
          align-items: flex-start;
          justify-content: center;
        }
      `}
    >
      {/* 字 */}
      <Container>
        <div
          css={css`
            color: #000000;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            ${Tablet} {
              color: #ffffff;
            }
            ${Desktop} {
              position: static;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              text-align: left;
              width: 633px;
              // padding: 173px 48px 0 117px;
              padding: 173px 48px 0 0px;
            }
          `}
        >
          <div
            css={css`
              color: #cccccc;
              font-weight: normal;
              line-height: 1.5;
              font-size: 6px;
              margin-bottom: 35px;
              width: 100%;
              padding: 0 20px;
              text-align: left;

              ${Tablet} {
                margin-bottom: 26px;
                line-height: 1.5;
                font-size: 10px;
                display: block;
                width: 768px;
                padding: 0 30px;
                text-align: left;
              }
              ${Desktop} {
                display: none;
              }
            `}
          >
            {intl.formatMessage({ id: 'individuals.stock-trading.attention' })}
          </div>
          <span
            css={css`
              font-size: 32px;
              font-weight: bold;
              line-height: 36px;
              ${Tablet} {
                font-size: 48px;
                font-weight: bold;
                line-height: 1.08;
                color: #161616;
              }
              ${Desktop} {
                font-size: 48px;
                font-weight: bold;
                line-height: 1.04;
                color: #ffffff;
              }
            `}
          >
            {intl.formatMessage({
              id: 'individuals.stock-trading.banner-title',
            })}
          </span>
          <span
            css={css`
              font-size: 18px;
              font-weight: normal;
              line-height: 20px;
              margin-top: 10px;
              width: 338px;
              ${Tablet} {
                margin-top: 20px;
                font-size: 24px;
                font-weight: normal;
                line-height: 1.38;
                color: #161616;
                width: 630px;
              }
              ${Desktop} {
                font-size: 24px;
                font-weight: normal;
                line-height: 1.38;
                margin-top: 28px;
                color: #ffffff;
                width: 468px;
              }
            `}
          >
            {intl.formatMessage({
              id: 'individuals.stock-trading.banner-description',
            })}
          </span>
          {/* <div
          css={css`
            ${Desktop} {
              height: 29px;
              width: 112px;
              border-radius: 40px;
              background: #ffffff;
              box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
              color: #ee2f79;
              line-height: 29px;
              font-size: 14px;
              font-weight: bold;
              text-align: center;
              margin-top: 34px;
            }
          `}
        >
          Learn More
        </div> */}
        </div>
      </Container>
      {/* video */}
      <div
        css={css`
          ${Tablet} {
            height: 462px;
            overflow: hidden;
          }
          ${Desktop} {
            position: absolute;
            justify-self: flex-end;
            width: 50%;
            height: 100%;
            // width: 615px;
            // width: 100%;
            right: 0;
          }
        `}
      >
        {/* WEB、IPAD端 */}
        <video
          css={css`
            // width: 100%;
            display: none;
            ${Tablet} {
              // height: 650px;
              display: block;
              width: 100%;
              transform: translateY(-15%);
            }
            ${Desktop} {
              // height: 100%;
              // width: 807px;
              width: 100%;
              transform: translateY(0);
              // z-index: 100;
            }
          `}
          src='/product/stock-trading/video/Stock-Trading-Banner.mp4'
          autoPlay
          muted
          loop
        ></video>
        {/* MOBILE端 */}
        {/* 苹果手机 - 自动播放video，请添加下面的属性 */}
        <video
          className='st-mobile-video'
          css={css`
            width: 100%;
            display: block;
            ${Tablet} {
              display: none;
            }
          `}
          src='/product/stock-trading/video/Stock-Trading-Banner.mp4'
          muted
          loop
          autoPlay
          x5-playsinline='true'
          playsinline='true'
          webkit-playsinline='true'
          preload='auto'
          x5-video-player-type='h5'
        ></video>
      </div>
    </div>
  );
};

export default StockTradingBanner;
